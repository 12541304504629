import { render, staticRenderFns } from "./ContactRequestInboundLeftSidebarContent.vue?vue&type=template&id=793cd54e&"
import script from "./ContactRequestInboundLeftSidebarContent.vue?vue&type=script&lang=js&"
export * from "./ContactRequestInboundLeftSidebarContent.vue?vue&type=script&lang=js&"
import style0 from "./ContactRequestInboundLeftSidebarContent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VBtn,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemTitle})
