<template>
  <div class="h-full">
    <!-- 1st Row -->
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <div
        class="d-flex align-center cursor-pointer mr-4"
        @click="$emit('close-contact-request-inbound-view')"
      >
        <!-- Back Button -->
        <v-btn
          icon
          small
          class="me-2"
        >
          <v-icon size="28">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>

        <!-- Subject -->
        <h1 class="font-weight-medium text-base me-2 text-truncate">
          <!-- {{ contactRequestInboundView.contactRequest.subject }} -->
          {{ getContactRequestInboundTitle(contactRequestInboundView) }}
        </h1>
      </div>

      <!-- Labels -->
      <v-badge
        v-for="label in contactRequestInboundView.labels"
        :key="label"
        :color="resolveContactRequestInboundLabelColor(label)"
        inline
        :content="label"
        class="email-label-chip text-capitalize v-badge-light-bg"
        :class="`${resolveContactRequestInboundLabelColor(label)}--text`"
      >
      </v-badge>
      <v-spacer></v-spacer>

      <!-- Navigation -->
      <v-btn
        icon
        small
        :disabled="!openContactRequestInboundMeta.hasPrev"
        @click="$emit('change-open-contact-request-inbound', 'prev')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        :disabled="!openContactRequestInboundMeta.hasNext"
        @click="$emit('change-open-contact-request-inbound', 'next')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>

    <v-divider></v-divider>

    <!-- 2nd Row: Actions -->
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <!-- Actions: Send trash (Only !== trash) -->
      <!-- <v-btn
        v-show="$route.params.folder !== 'trash'"
        icon
        small
        class="me-2"
        @click="$emit('move-contact-request-inbound-to-folder', 'trash')"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn> -->

      <!-- Actions: Set Unread (Set read is not posible becose is readed yet) -->
      <v-btn
        icon
        small
        class="me-2"
        @click="$emit('mark-contact-request-inbound-unread')"
      >
        <v-icon size="22">
          {{ icons.mdiEmailOutline }}
        </v-icon>
      </v-btn>

      <!-- Actions: Set labels to ['Personal', 'Company', ...] -->
      <MenuLabels
        :is-visible="true"
        :update-label-menu-list-items="updateLabelMenuListItems"
        @on-label-click="(value) => $emit('update-contact-request-inbound-label', value)"
      ></MenuLabels>

      <v-spacer></v-spacer>
      <!-- Other actions: Accepted | Rejected -->
      <div class="d-flex align-center">
        <!-- Show status -->
        <div class="d-flex align-center mr-2">
          <!-- :color="resolveContactRequestInboundStatus(contactRequestInboundView.status).color" -->
          <v-badge
            inline
            class="email-label-chip text-capitalize v-badge-light-bg"
            :class="`${resolveContactRequestInboundStatus(contactRequestInboundView.status).color}--text`"
            :content="contactRequestInboundView.status"
          />
          <ContactChatMessageNavegable
            v-if="contactRequestInboundView.contactRelation"
            :contact="contactRequestInboundView.contactRelation"
            :is-navegable="true"
          />
        </div>

        <!-- Custom Actions: Accepted | Rejected -->
        <v-btn
          v-if="contactRequestInboundView.status === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED"
          :icon="!$vuetify.breakpoint.smAndUp"
          outlined
          small
          class="ml-2"
          :color="customActionAccepted.color"
          @click="$emit('set-contact-request-inbound-action', customActionAccepted.value)"
        >
          <v-icon size="22">
            {{ customActionAccepted.icon }}
          </v-icon>
          <span
            v-if="$vuetify.breakpoint.smAndUp"
            class="pl-2"
          >{{ customActionAccepted.title }}</span>
        </v-btn>
        <v-btn
          v-if="contactRequestInboundView.status === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED"
          icon
          outlined
          small
          class="ml-2"
          :color="customActionRejected.color"
          @click="$emit('set-contact-request-inbound-action', customActionRejected.value)"
        >
          <v-icon size="22">
            {{ customActionRejected.icon }}
          </v-icon>
        </v-btn>

        <!-- DELETE -->
        <v-btn
          icon
          small
          outlined
          class="ml-2"
          color="error"
          @click="$emit('on-delete', contactRequestInboundView)"
        >
          <v-icon size="22">
            {{ icons.mdiTrashCanOutline }}
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>

    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-contact-request-inbound-view-content"
    >
      <!-- ContactRequestInbound Messages -->
      <div
        class="position-relative ma-sm-7 ma-4"
      >
        <!-- <contact-request-inbound-message-card
          :message="contactRequestInboundView"
          style="top:0;z-index:10"
        /> -->

        <!-- Cards: Supplier and ProductSupplier -->
        <v-card
          outlined
          class="mt-4"
          style="top:0;z-index:10"
        >
          <!--  -->
          <v-card-text class="d-flex flex-wrap align-center pa-4">
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="contactRequestInboundView.isStarred ? 'warning': null"
              @click.stop="$emit('toggle-contact-request-inbound-starred', contactRequestInboundView)"
            >
              <v-icon>
                {{ contactRequestInboundView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Cards: Demander(His) and ProductSupplier(My) -->
            <ContactRequestInboundCard
              :contact-request-inbound="contactRequestInboundView"
              class="flex-wrap"
            />

            <!-- Cards: Demander(His) and ProductSupplier(My) -->
            <!-- <DemanderCard :demander="contactRequestInboundView.contactRequest.demander" /> -->
            <!-- <ProductSupplierCard :product-supplier="contactRequestInboundView.contactRequest.productSupplier" /> -->

            <!-- Right -->
            <v-spacer></v-spacer>
            <span
              v-if="$vuetify.breakpoint.smAndUp"
              class="text--disabled text-xs me-2 text-no-wrap"
            >
              {{ formatDate(contactRequestInboundView.createdAt, { dateStyle: 'long', timeStyle: 'short' }) }}
            </span>
            <div
              v-else
              class="d-flex flex-column align-center"
            >
              <v-btn
                icon
                small
                :color="contactRequestInboundView.isStarred ? 'warning': null"
                @click.stop="$emit('toggle-contact-request-inbound-starred', contactRequestInboundView)"
              >
                <v-icon>
                  {{ contactRequestInboundView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
                </v-icon>
              </v-btn>
              <span class="text--disabled text-xs">{{ formatDateToMonthShort(contactRequestInboundView.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <!-- Message -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="ma-5"
            v-html="contactRequestInboundView.contactRequest.message"
          ></div>
        </v-card>

        <!-- Content -->
        <v-row class="mt-4">
          <v-col
            cols="12"
            lg="6"
            md="6"
          >
            <ContactRequestInboundContent :content-data="contactRequestInboundView"></ContactRequestInboundContent>
          </v-col>
        </v-row>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { formatDate, formatDateToMonthShort } from '@core/utils/filter'
import {
  mdiArrowCollapseVertical,
  mdiArrowExpandVertical,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiDotsVertical,
  mdiEmailOutline,
  mdiFolderOutline,
  mdiLabelOutline,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import useContactRequestInboundHandler from './useContactRequestInboundHandler'

// Local Components
// import ContactRequestInboundViewMessageCard from './ContactRequestInboundViewMessageCard.vue'
// import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
// import DemanderCard from '@/components/Cards/DemanderCard.vue'
import ContactRequestInboundCard from '@/components/Cards/ContactRequestInboundCard.vue'
import ContactRequestInboundContent from './ContactRequestInboundContent.vue'
import MenuLabels from '@/components/MenuLabels.vue'

import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'

export default {
  components: {
    PerfectScrollbar,

    // Local Components
    ContactRequestInboundCard,
    ContactRequestInboundContent,
    MenuLabels,

    ContactChatMessageNavegable,
  },
  props: {
    contactRequestInboundView: {
      type: Object,
      required: true,
    },
    openContactRequestInboundMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // UserName + CompanyName
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getCompanyName = company => company?.general?.name || ''

    // Title: User + Company
    const getContactRequestInboundTitle = contactRequestInbound => `${getUserName(contactRequestInbound?.contactRequest?.demander?.user)} (${getCompanyName(contactRequestInbound?.contactRequest?.demander?.company)})`

    // ————————————————————————————————————
    //* ——— useContactRequestInbound
    // ————————————————————————————————————
    const {
      resolveContactRequestInboundLabelColor,
      resolveContactRequestInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CONTACT_REQUEST_INBOUND_STATUS,
    } = useContactRequestInboundHandler()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      getContactRequestInboundTitle, // User + Company

      // useContactRequestInbound
      resolveContactRequestInboundLabelColor,
      resolveContactRequestInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CONTACT_REQUEST_INBOUND_STATUS,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiTrashCanOutline,
        mdiEmailOutline,
        mdiFolderOutline,
        mdiLabelOutline,
        mdiArrowExpandVertical,
        mdiArrowCollapseVertical,
        mdiDotsVertical,
        mdiStarOutline,
        mdiStar,
        mdiClose,
      },
      formatDate,
      formatDateToMonthShort,
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/preset/preset/mixins.scss';
  // @import '~vuetify/src/components/VCard/_variables.scss';

  @include theme--child(ps-contact-request-inbound-view-content) using ($material) {
    background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
  }

  .ps-contact-request-inbound-view-content {
    height: calc(100% - 46px - 46px - 2px);

    // .view-all-toggler {
    //   &:hover {
    //     color: var(--v-primary-base) !important;
    //   }
    // }
  }
</style>
