import axios from '@axios'

// interface ContactRequestInboundInterface {
//     id: string;
//     name: string;
// }

// ContactRequestInbound Router list
// GET    /marketplace/contact_request/inbounds             Retrieves the collection of ContactRequestInbound resources.
// GET    /marketplace/contact_request/inbounds/{id}        Retrieves a ContactRequestInbound resource.
// DELETE /marketplace/contact_request/inbounds/{id}        Removes a ContactRequestInbound resource.
//
// Other Actions
// POST   /marketplace/contact_request/inbounds/status/{id}  Change status  of a ContactRequestInbound resource.
// POST   /marketplace/contact_request/inbounds/starred/{id} Change starred of a ContactRequestInbound resource.
// POST   /marketplace/contact_request/inbounds/readed/{id}  Change readed  of a ContactRequestInbound resource.
// POST   /marketplace/contact_request/inbounds/labels/{id}  Change label   of a ContactRequestInbound resource.
const routeMarketingContactRequestInbounds = '/marketplace/contact_request/inbounds'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// class ContactRequestInbound extends BaseApiService { constructor() { super(axios) }
class ContactRequestInbound {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchContactRequestInbounds(queryParams) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingContactRequestInbounds}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // Optional click count when read
  fetchContactRequestInbound(id, clickCount = false) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeMarketingContactRequestInbounds}/${id}`, { params: { clickCount } })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeContactRequestInbound(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeMarketingContactRequestInbounds}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  // { contact_requestInboundId, status: [accepted | rejected] }
  changeContactRequestInboundStatus(contactRequestInboundId, newStatus) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingContactRequestInbounds}/status/${contactRequestInboundId}`, {
          status: newStatus,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { contact_requestInboundId, isStarred: [true | false] }
  changeContactRequestInboundStarred(contactRequestInboundId, newIsStarred) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingContactRequestInbounds}/starred/${contactRequestInboundId}`, {
          isStarred: newIsStarred,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { contact_requestInboundId, isRead: [true | false] }
  changeContactRequestInboundReaded(contactRequestInboundId, newIsRead) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeMarketingContactRequestInbounds}/readed/${contactRequestInboundId}`, {
          isRead: newIsRead,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { contact_requestInboundId, label: ["important" | "company" ...] }
  changeContactRequestInboundLabels(contactRequestInboundId, toggleLabel) {
    return new Promise((resolve, reject) => {
      this.axiosIns

        // .post('/marketplace/contact_request/inbound/update-label', payload)
        .post(`${this.API_BASE}${routeMarketingContactRequestInbounds}/labels/${contactRequestInboundId}`, {
          label: toggleLabel,
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useContactRequestInbound (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useContactRequestInbound = new ContactRequestInbound()
export default useContactRequestInbound
