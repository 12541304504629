<template>
  <v-card
    class="rounded-0 app-contact-request-inbound max-h-content-container h-full-- position-relative overflow-hidden text-sm"
  >
    <!-- Dialog confirm Accept contact -->
    <ContactRequestInboundAcceptDialog
      v-model="isContactRequestInboundAcceptDialogVisible"
      :contact-request-inbound="contactRequestInboundAcceptDialog"
      @ok="(contactRequestInbound) => askContactRequestInboundAcceptDialogResponse(contactRequestInbound)"
    ></ContactRequestInboundAcceptDialog>

    <!-- Delete -->
    <DialogDelete
      :id="contactRequestInboundDeleteId"
      v-model="isContactRequestInboundDeleteDialogActive"
      :dependencies-error="contactRequestInboundDeleteDependenciesError"
      :title="$t('ContactRequestInboundList.table.dialog_delete.title')"
      :text="$t('ContactRequestInboundList.table.dialog_delete.text')"
      :button="$t('ContactRequestInboundList.table.dialog_delete.button_text')"
      @delete="(id) => onContactRequestInboundDelete(id, false)"
    ></DialogDelete>

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="260"
      mobile-breakpoint="sm"
      absolute
      :right="$vuetify.rtl"
      touchless
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <contact-request-inbound-left-sidebar-content
        :contact-request-inbounds-meta="contactRequestInboundsMeta"
      ></contact-request-inbound-left-sidebar-content>
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative d-flex flex-column"
      :style="contentStyles"
    >
      <!-- 1st Row -->
      <div class="d-flex align-center px-4">
        <v-btn
          v-show="!isLeftSidebarOpen"
          :icon="false"
          outlined
          small
          color="primary"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="22">
            {{ icons.mdiTune }}
          </v-icon>
          <span class="pl-2">{{ $route.params.folder || $route.params.label }}</span>
        </v-btn>
        <v-text-field
          v-model="searchQuery"
          placeholder="search..."
          outlined
          hide-details
          class="contact-request-inbound-search-input"
          :prepend-inner-icon="icons.mdiMagnify"
        ></v-text-field>
      </div>

      <v-divider></v-divider>

      <!-- 2nd Row: Action Multiple -->
      <div class="d-flex align-center px-4 py-2">
        <v-checkbox
          class="d-flex mt-0 pt-0"
          hide-details
          :input-value="selectAllContactRequestInboundCheckbox"
          :indeterminate="isSelectAllContactRequestInboundCheckboxIndeterminate"
          :disabled="!contactRequestInbounds.length"
          @change="selectAllCheckboxUpdate"
        ></v-checkbox>

        <!-- Action Multiple: Send to delete -->
        <!-- <v-btn
          v-show="selectedContactRequestInbounds.length && $route.params.folder !== 'trash'"
          icon
          small
          class="me-3"
          @click="moveSelectedContactRequestInboundToFolderMultiple('trash')"
        >
          <v-icon size="22">
            {{ icons.mdiTrashCanOutline }}
          </v-icon>
        </v-btn> -->

        <!-- Multiple: Basic Actions: Read / Unread -->
        <v-btn
          v-show="selectedContactRequestInbounds.length"
          icon
          small
          class="me-3"
          @click="isAllSelectedContactRequestInboundAreRead ? markSelectedContactRequestInboundAsUnreadMultiple() : markSelectedContactRequestInboundAsReadMultiple()"
        >
          <v-icon size="22">
            {{ isAllSelectedContactRequestInboundAreRead ? icons.mdiEmailOutline : icons.mdiEmailOpenOutline }}
          </v-icon>
        </v-btn>

        <!-- Multiple: Basic Actions : Labels -->
        <MenuLabels
          :is-visible="(selectedContactRequestInbounds.length > 0)"
          :update-label-menu-list-items="updateLabelMenuListItems"
          @on-label-click="(value) => updateSelectedContactRequestInboundLabelMultiple(value)"
        ></MenuLabels>

        <!-- Multiple: Custom Actions -->
        <!-- v-if="$route.params.folder === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED" -->
        <div
          v-if="selectedContactRequestInbounds.length"
          class="me-2"
        >
          <div></div>
          <v-btn
            v-if="$route.params.folder === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED"
            icon
            small
            class="me-2"
            :color="customActionRejected.color"
            @click.stop="setSelectedContactRequestInboundActionMultiple(customActionRejected.value)"
          >
            <v-icon size="22">
              {{ customActionRejected.icon }}
            </v-icon>
          </v-btn>

          <v-btn
            v-if="$route.params.folder === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED"
            icon
            small
            class="me-2"
            :color="customActionAccepted.color"
            @click.stop="setSelectedContactRequestInboundActionMultiple(customActionAccepted.value)"
          >
            <v-icon size="22">
              {{ customActionAccepted.icon }}
            </v-icon>
          </v-btn>

          <!-- DELETE -->
          <v-btn
            v-if="$route.params.folder === CONTACT_REQUEST_INBOUND_STATUS.REJECTED"
            icon
            small
            outlined
            class="me-2"
            color="error"
            @click.stop="deleteSelectedContactRequestInboundMultiple()"
          >
            <v-icon size="22">
              {{ icons.mdiTrashCanOutline }}
            </v-icon>
          </v-btn>
        </div>

        <!-- Right Side -->
        <v-spacer></v-spacer>
        <v-btn
          v-if="!finished"
          text
          small
          class="ms-2"
          @click="onLoad()"
        >
          Load more
        </v-btn>
        <v-btn
          icon
          small
          class="ms-2"
          @click="onRefresh()"
        >
          <v-icon size="21">
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
      </div>

      <!-- Loading indicator -->
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        class="position-absolute"
      ></v-progress-linear>
      <v-divider></v-divider>

      <!-- ContactResquests List -->
      <!-- <perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-contact-request-inbounds-list"
      > -->
      <vue-loadmore
        ref="refLoadMore"
        :on-refresh="onRefresh"
        :on-loadmore="onLoad"
        :finished="finished"
        :error.sync="loadMoreError"
      >
        <v-hover
          v-for="contactRequestInbound in contactRequestInbounds"
          #default="{ hover: isHovered }"
          :key="contactRequestInbound.id"
        >
          <div
            :class="[{'hovered elevation-3': isHovered}, { 'contact-request-inbound-read': contactRequestInbound.isRead }]"
            class="contact-request-inbound-item pa-4 d-flex align-center text-no-wrap cursor-pointer"
            @click="updateContactRequestInboundView(contactRequestInbound)"
          >
            <v-checkbox
              class="d-flex mt-0 pt-0"
              hide-details
              :input-value="selectedContactRequestInbounds.includes(contactRequestInbound.id)"
              @click.stop
              @change="toggleSelectedContactRequestInbound(contactRequestInbound.id)"
            ></v-checkbox>
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="contactRequestInbound.isStarred ? 'warning': null"
              @click.stop="toggleStarred(contactRequestInbound)"
            >
              <v-icon>
                {{ contactRequestInbound.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Cards: Demander(His) and ProductSupplier(My) -->
            <ContactRequestInboundCard
              :contact-request-inbound="contactRequestInbound"
              :width="$vuetify.breakpoint.smAndUp ? '250px' : '200px'"
            />

            <!-- Subject + Labels -->
            <div class="d-flex flex-column text-truncate">
              <!-- <span
                class="text-truncate me-4 ms-sm-2 ms-0"
                :class="contactRequestInbound.isRead ? '' : 'font-weight-bold'"
                :style="contactRequestInbound.isRead ? '' : 'color: black'"
              >
                {{ contactRequestInbound.contactRequest.subject }}
              </span> -->
              <div class="d-flex flex-row text-truncate">
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <!-- labels  > 1: Indicamos solo los puntos (Sin Opacidad) -->
                  <!-- labels == 1: Indicamos el texto (Con Opacidad) -->
                  <v-badge
                    v-for="label in contactRequestInbound.labels"
                    :key="label"
                    :color="resolveContactRequestInboundLabelColor(label)"
                    :dot="contactRequestInbound.labels.length > 1"
                    inline
                    class="align-self-center label-dot"
                    :class="!(contactRequestInbound.labels.length > 1) ? `${resolveContactRequestInboundLabelColor(label)}--text v-badge-light-bg` : ''"
                    :content="label"
                  ></v-badge>
                </template>
              </div>
            </div>

            <v-spacer></v-spacer>

            <!-- Time and Actions On Hover -->
            <div v-show="isHovered">
              <ContactChatMessageNavegable
                v-if="contactRequestInbound.contactRelation"
                :contact="contactRequestInbound.contactRelation"
                :is-navegable="true"
              />

              <!-- Action Read / Unread -->
              <v-btn
                icon
                small
                class="me-2"
                @click.stop="contactRequestInbound.isRead ? markSelectedContactRequestInboundAsUnreadMultiple([contactRequestInbound.id]) : markSelectedContactRequestInboundAsReadMultiple([contactRequestInbound.id])"
              >
                <v-icon size="22">
                  {{ contactRequestInbound.isRead ? icons.mdiEmailOutline : icons.mdiEmailOpenOutline }}
                </v-icon>
              </v-btn>

              <!-- Custom Actions: Accepted | Rejected -->
              <!-- Received: La puedo aceptar (Accepted) -->
              <!-- @click.stop="setSelectedContactRequestInboundActionMultiple(customActionAccepted.value, [contactRequestInbound.id])" -->
              <v-btn
                v-if="contactRequestInbound.status === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED"
                icon
                small
                class="me-2"
                :color="customActionAccepted.color"
                @click.stop="askContactRequestInboundAcceptDialog(contactRequestInbound)"
              >
                <v-icon size="22">
                  {{ customActionAccepted.icon }}
                </v-icon>
              </v-btn>

              <!-- Received: La puedo rechazar (Rejected) -->
              <v-btn
                v-if="contactRequestInbound.status === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED"
                icon
                small

                class="me-2"
                :color="customActionRejected.color"
                @click.stop="setSelectedContactRequestInboundActionMultiple(customActionRejected.value, [contactRequestInbound.id])"
              >
                <v-icon size="22">
                  {{ customActionRejected.icon }}
                </v-icon>
              </v-btn>

              <!-- Action: Delete permanent (Only when finished) -->
              <!-- @click.stop="deleteSelectedContactRequestInboundMultiple([contactRequest.id])" -->
              <v-btn
                v-if="contactRequestInbound.status === CONTACT_REQUEST_INBOUND_STATUS.REJECTED"
                icon
                small
                class="me-2"
                color="error"
                @click.stop="askContactRequestInboundDelete(contactRequestInbound.id)"
              >
                <v-icon size="22">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </div>

            <!-- Not hovered: Estatus + Time -->
            <div v-show="!isHovered">
              <div class="d-flex flex-column align-center">
                <span class="text-xs text--disabled">{{ formatDateToMonthShort(contactRequestInbound.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
                <v-badge
                  inline
                  class="email-label-chip text-capitalize v-badge-light-bg"
                  :class="`${resolveContactRequestInboundStatus(contactRequestInbound.status).color}--text`"
                  :content="contactRequestInbound.status"
                ></v-badge>
              </div>
            </div>
          </div>
        </v-hover>
        <div
          class="d-none text-center text--primary font-weight-medium"
          :class="{'d-block': !contactRequestInbounds.length}"
        >
          <p class="my-4">
            No Items Found
          </p>
        </div>
        <!-- LoadMore button -->
        <v-progress-linear
          v-if="loading"
          indeterminate
          reverse
          color="primary"
          class="position-absolute"
        ></v-progress-linear>
        <v-divider></v-divider>
        <div class="d-flex justify-center">
          <v-btn
            v-if="!finished"
            text
            small
            class="me-2"
            @click="onLoad()"
          >
            Load more
          </v-btn>
        </div>
      </vue-loadmore>
      <!-- </perfect-scrollbar> -->

      <!-- ContactRequestInbound Details -->
      <v-navigation-drawer
        v-model="isContactRequestInboundViewOpen"
        :right="!$vuetify.rtl"
        absolute
        mobile-breakpoint="sm"
        width="100%"
      >
        <!-- @move-contact-request-inbound-to-folder="moveOpenContactRequestInboundToFolder" -->
        <contact-request-inbound-view
          v-if="contactRequestInboundView.contactRequest"
          :contact-request-inbound-view="contactRequestInboundView"
          :open-contact-request-inbound-meta="openContactRequestInboundMeta"
          @change-open-contact-request-inbound="changeOpenContactRequestInbound"
          @close-contact-request-inbound-view="isContactRequestInboundViewOpen = false"
          @mark-contact-request-inbound-unread="markOpenContactRequestInboundAsUnread"
          @update-contact-request-inbound-label="updateOpenContactRequestInboundLabel"

          @toggle-contact-request-inbound-starred="(contactRequestInbound) => toggleStarred(contactRequestInbound)"
          @set-contact-request-inbound-action="(status) => setOpenContactRequestInboundAction(status)"
          @on-delete="(contactRequestInbound) => askContactRequestInboundDelete(contactRequestInbound.id)"
        ></contact-request-inbound-view>
      </v-navigation-drawer>
    </div>
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { useRouter } from '@core/utils'
import {
  mdiTune,
  mdiMagnify,
  mdiTrashCanOutline,
  mdiReload,
  mdiStar,
  mdiStarOutline,
  mdiEmailOpenOutline,
  mdiEmailOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// Store
import { formatDateToMonthShort } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Services
import useContactRequestInbound from '@/services/api/modules/contactRequestInbound'
import useContactRequestInboundHandler from './useContactRequestInboundHandler'

// Local Componnets
// import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
// import DemanderCard from '@/components/Cards/DemanderCard.vue'
import ContactRequestInboundCard from '@/components/Cards/ContactRequestInboundCard.vue'
import ContactRequestInboundLeftSidebarContent from './ContactRequestInboundLeftSidebarContent.vue'
import ContactRequestInboundView from './ContactRequestInboundView.vue'
import ContactRequestInboundAcceptDialog from './ContactRequestInboundAcceptDialog.vue'
import MenuLabels from '@/components/MenuLabels.vue'

import DialogDelete from '@/components/DialogDelete.vue'

import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'

export default {
  components: {
    ContactRequestInboundLeftSidebarContent,
    ContactRequestInboundView,
    ContactRequestInboundCard,
    ContactRequestInboundAcceptDialog,
    MenuLabels,

    DialogDelete,
    ContactChatMessageNavegable,

    // 3rd Party
    // eslint-disable-next-line
    PerfectScrollbar,
  },
  setup() {
    // Register module
    // const MARKETING_INBOUND_APP_STORE_MODULE_NAME = 'marketing-inbound'
    // if (!store.hasModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME)) store.registerModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME, contactRequestInboundStoreModule)
    // onUnmounted(() => {
    //   if (store.hasModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME)) store.unregisterModule(MARKETING_INBOUND_APP_STORE_MODULE_NAME)
    // })

    // LeftSidebar management (Integrated with Main Component)
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 260 })

    // ————————————————————————————————————
    //* ——— Router
    // ————————————————————————————————————
    const { route, router } = useRouter()

    // Search Query & Route Params
    const searchQuery = computed({
      get: () => route.value.query.q,
      set: val => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      },
    })
    const routeParams = computed(() => route.value.params)

    // Fetch contactRequestInbound again of search query change or route param change
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, routeParams], () => fetchContactRequestInbounds())

    // ————————————————————————————————————
    //* ——— useContactRequestInbound
    // ————————————————————————————————————
    const {
      sendContactRequestInboundNotification,
      resolveContactRequestInboundLabelColor,
      resolveContactRequestInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CONTACT_REQUEST_INBOUND_STATUS,
    } = useContactRequestInboundHandler()

    // ————————————————————————————————————
    //* ——— ContactRequestInbounds & ContactRequestInbounds Meta (LeftSide)
    // ————————————————————————————————————
    const contactRequestInbounds = ref([])
    const contactRequestInboundsMeta = ref({})
    const loading = ref(false)
    const finished = ref(false)
    const loadMoreError = ref(false)
    const refLoadMore = ref(null)

    const scrollToToInLoadMore = () => {
      const scrollEl = refLoadMore.value.$el || refLoadMore.value
      scrollEl.scrollTop = 0
    }

    // Options: Pagination, ... (See useMarketplaceCatalog for example)
    const itemsPerPageBase = 6
    const options = ref({
      page: 1,
      itemsPerPage: itemsPerPageBase,
    })

    // When pull refresh
    const onRefresh = done => {
      // Reset pagination
      options.value.page = 1
      options.value.itemsPerPage = itemsPerPageBase

      finished.value = false

      // eslint-disable-next-line no-use-before-define
      fetchContactRequestInbounds()

      scrollToToInLoadMore()
      if (done !== undefined) {
        done()
      }
    }

    // When scroll to the botton
    const onLoad = done => {
      console.log('onLoadMore')
      if (!finished.value) {
        // options.value.page += 1
        options.value.itemsPerPage += itemsPerPageBase

        // eslint-disable-next-line no-use-before-define
        fetchContactRequestInbounds()
      }

      if (done !== undefined) {
        done()
      }
    }

    const fetchContactRequestInbounds = () => {
      loading.value = true

      //   store.dispatch('marketing/fetchContactRequestInbounds', {
      // folder meaning
      //  received filter by status (Default search)
      //  finished filter by status
      //  rejected filter by status
      //  starred  filter by isStarred
      //  trash    filter by isDeleted or status deleted
      useContactRequestInbound
        .fetchContactRequestInbounds({
          q: searchQuery.value,
          perPage: options.value.itemsPerPage,
          page: options.value.page,

          // folder: router.currentRoute.params.folder || 'received',
          folder:
            router.currentRoute.params.folder
            || (router.currentRoute.params.label ? undefined : CONTACT_REQUEST_INBOUND_STATUS.RECEIVED),
          label: router.currentRoute.params.label,
        })
        .then(response => {
          const {
            contactRequestInbounds: contactRequestInboundsResponse,
            contactRequestInboundsMeta: contactRequestInboundsMetaResponse,
            total,
          } = response.data
          if (contactRequestInboundsResponse) {
            contactRequestInbounds.value = contactRequestInboundsResponse
            contactRequestInboundsMeta.value = contactRequestInboundsMetaResponse
          } else {
            contactRequestInbounds.value = []
            contactRequestInboundsMeta.value = {}
          }
          finished.value = (options.value.page * options.value.itemsPerPage) >= total

          // Store
          // const totalPending = contactRequestInboundsMeta.value['CONTACT_REQUEST_INBOUND_STATUS.RECEIVED'] || 0
          // const totalPending = contactRequestInboundsMeta.value?.received || 0
          const { received } = contactRequestInboundsMeta.value
          store.commit('user/UPDATE_CONTACT_REQUEST_INBOUNDS_PENDING_COUNT', received || 0)
        })
        .catch(() => {
          loadMoreError.value = true
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchContactRequestInbounds()

    // Navegación: Se ha indicado un id (Buscamos en los query params)
    //  Navegación automática -> TODO - Mejor abrir una modal de edición (Por ejemplo ContactRequestInboundComposerDialog)
    const contactRequestInboundIdNavigation = route.value.query.contactRequestInboundId
    const fetchContactRequestInbound = contactRequestInboundId => {
      useContactRequestInbound.fetchContactRequestInbound(contactRequestInboundId)
        .then(response => {
          // eslint-disable-next-line
          updateContactRequestInboundView(response.data.contactRequestInbound)
        })
    }
    if (contactRequestInboundIdNavigation) {
      fetchContactRequestInbound(contactRequestInboundIdNavigation)
    }

    // ————————————————————————————————————
    //* ——— ContactRequestInbounds Selection
    // ————————————————————————————————————
    const selectedContactRequestInbounds = ref([])
    const toggleSelectedContactRequestInbound = contactRequestInboundId => {
      const contactRequestInboundIndex = selectedContactRequestInbounds.value.indexOf(contactRequestInboundId)

      if (contactRequestInboundIndex === -1) selectedContactRequestInbounds.value.push(contactRequestInboundId)
      else selectedContactRequestInbounds.value.splice(contactRequestInboundIndex, 1)
    }
    const selectAllContactRequestInboundCheckbox = computed(
      () => contactRequestInbounds.value.length
        && contactRequestInbounds.value.length === selectedContactRequestInbounds.value.length,
    )
    const isSelectAllContactRequestInboundCheckboxIndeterminate = computed(
      () => Boolean(selectedContactRequestInbounds.value.length)
        && contactRequestInbounds.value.length !== selectedContactRequestInbounds.value.length,
    )
    const selectAllCheckboxUpdate = val => {
      selectedContactRequestInbounds.value = val
        ? contactRequestInbounds.value.map(contactRequestInbound => contactRequestInbound.id)
        : []
    }
    const isAllSelectedContactRequestInboundAreRead = computed(() => {
      // Get array of isRead property value from selected contactRequestInbounds array
      const contactRequestInboundsIsRead = selectedContactRequestInbounds.value.map(
        contactRequestInboundId => contactRequestInbounds.value.find(
          contactRequestInbound => contactRequestInbound.id === contactRequestInboundId,
        ).isRead,
      )

      // Return icon based on selection (every comprueba si todos los elementos cumplen una condición)
      return contactRequestInboundsIsRead.every(contactRequestInboundIsRead => contactRequestInboundIsRead)
    })

    // ? Watcher to reset selectedContactRequestInbounds is somewhere below due to watch dependecy fullfilment

    // ————————————————————————————————————
    //* ——— ContactRequestInbound Actions
    // ————————————————————————————————————
    const toggleStarred = contactRequestInbound => {
      useContactRequestInbound.changeContactRequestInboundStarred(
        contactRequestInbound.id,
        !contactRequestInbound.isStarred,
      )
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          contactRequestInbound.isStarred = !contactRequestInbound.isStarred
        })
    }

    const updateSelectedContactRequestInboundLabelMultiple = (
      label,
      contactRequestInboundIds = selectedContactRequestInbounds.value,
    ) => {
      const tasks = contactRequestInboundIds.map(contactRequestInboundId => useContactRequestInbound.changeContactRequestInboundLabels(contactRequestInboundId, label))
      Promise.all(tasks).finally(() => {
        fetchContactRequestInbounds()
        selectedContactRequestInbounds.value = []
      })
    }

    const markSelectedContactRequestInboundAsUnreadMultiple = (
      contactRequestInboundIds = selectedContactRequestInbounds.value,
    ) => {
      const tasks = contactRequestInboundIds.map(contactRequestInboundId => useContactRequestInbound.changeContactRequestInboundReaded(contactRequestInboundId, false))
      Promise.all(tasks).finally(() => {
        selectedContactRequestInbounds.value = []
        fetchContactRequestInbounds()
      })
    }

    const markSelectedContactRequestInboundAsReadMultiple = (
      contactRequestInboundIds = selectedContactRequestInbounds.value,
    ) => {
      const tasks = contactRequestInboundIds.map(contactRequestInboundId => useContactRequestInbound.changeContactRequestInboundReaded(contactRequestInboundId, true))
      Promise.all(tasks).finally(() => {
        selectedContactRequestInbounds.value = []
        fetchContactRequestInbounds()
      })
    }

    // Pendiente (Igual no se permite)
    const deleteSelectedContactRequestInboundMultiple = (contactRequestInboundIds = selectedContactRequestInbounds.value) => {
      const tasks = contactRequestInboundIds.map(contactRequestInboundId => useContactRequestInbound.removeContactRequestInbound(contactRequestInboundId))
      Promise.all(tasks).finally(() => {
        fetchContactRequestInbounds()
        selectedContactRequestInbounds.value = []
      })
    }

    // Custom Actions: [ accepted | rejected ]
    // const setSelectedContactRequestInboundAction = (
    //   action,
    //   contactRequestInboundId,
    // ) => {
    //   // if (action === CONTACT_REQUEST_INBOUND_STATUS.ACCEPTED || action === CONTACT_REQUEST_INBOUND_STATUS.REJECTED) {
    //   useContactRequestInbound
    //     .changeContactRequestInboundStatus(contactRequestInboundId, action)
    //     .then(() => {
    //       sendContactRequestInboundNotification(`ContactRequest Inbound ${action}`)
    //     })
    //     .finally(() => {
    //       selectedContactRequestInbounds.value = []
    //       fetchContactRequestInbounds()
    //     })
    // }
    const setSelectedContactRequestInboundActionMultiple = (
      status,
      contactRequestInboundIds = selectedContactRequestInbounds.value,
    ) => {
      const tasks = contactRequestInboundIds.map(contactRequestInboundId => useContactRequestInbound.changeContactRequestInboundStatus(contactRequestInboundId, status))
      Promise.all(tasks).finally(() => {
        fetchContactRequestInbounds()
        selectedContactRequestInbounds.value = []
      })
    }

    // ————————————————————————————————————
    //* ——— ContactRequestInbound View
    // ————————————————————————————————————
    const isContactRequestInboundViewOpen = ref(false)
    const contactRequestInboundView = ref({})

    // >>> Open Meta (Prev < > Next)
    const openContactRequestInboundMeta = computed(() => {
      const openContactRequestInboundIndex = contactRequestInbounds.value.findIndex(e => e.id === contactRequestInboundView.value.id)

      return {
        hasNext: Boolean(contactRequestInbounds.value[openContactRequestInboundIndex + 1]),
        hasPrev: Boolean(contactRequestInbounds.value[openContactRequestInboundIndex - 1]),
      }
    })
    const changeOpenContactRequestInbound = dir => {
      const openContactRequestInboundIndex = contactRequestInbounds.value.findIndex(
        e => e.id === contactRequestInboundView.value.id,
      )
      const newContactRequestInboundIndex = dir === 'prev' ? openContactRequestInboundIndex - 1 : openContactRequestInboundIndex + 1
      contactRequestInboundView.value = contactRequestInbounds.value[newContactRequestInboundIndex]
    }

    const updateContactRequestInboundView = contactRequestInbound => {
      // Mark contactRequestInbound is read
      if (!contactRequestInbound.isRead) {
        useContactRequestInbound
          .changeContactRequestInboundReaded(contactRequestInbound.id, true)
          .then(() => {
            // If open contactRequestInbound is unread then decrease badge count for contactRequestInbound meta based on contactRequestInbound folder
            if (
              !contactRequestInbound.isRead
              && contactRequestInbound.status === CONTACT_REQUEST_INBOUND_STATUS.RECEIVED
            ) {
              contactRequestInboundsMeta.value[contactRequestInbound.status] -= 1
            }
            // eslint-disable-next-line no-param-reassign
            contactRequestInbound.isRead = true

            // Store
            store.commit(
              'user/UPDATE_CONTACT_REQUEST_INBOUNDS_PENDING_COUNT',
              store.getters['user/contactRequestInboundsPendingCount'] - 1,
            )
          })
          .finally(() => {
            contactRequestInboundView.value = contactRequestInbound
            isContactRequestInboundViewOpen.value = true
          })
      } else {
        contactRequestInboundView.value = contactRequestInbound
        isContactRequestInboundViewOpen.value = true
      }
    }

    const updateOpenContactRequestInboundLabel = label => {
      selectedContactRequestInbounds.value = [contactRequestInboundView.value.id]
      updateSelectedContactRequestInboundLabelMultiple(label)

      // Update label in open contactRequestInbound
      const labelIndex = contactRequestInboundView.value.labels.indexOf(label)
      if (labelIndex === -1) contactRequestInboundView.value.labels.push(label)
      else contactRequestInboundView.value.labels.splice(labelIndex, 1)

      selectedContactRequestInbounds.value = []
    }

    const markOpenContactRequestInboundAsUnread = () => {
      selectedContactRequestInbounds.value = [contactRequestInboundView.value.id]
      markSelectedContactRequestInboundAsUnreadMultiple()

      selectedContactRequestInbounds.value = []
      isContactRequestInboundViewOpen.value = false
    }

    // Actions: Accept, Reject
    const setOpenContactRequestInboundAction = status => {
      selectedContactRequestInbounds.value = [contactRequestInboundView.value.id]

      if (status === CONTACT_REQUEST_INBOUND_STATUS.ACCEPTED) {
        // eslint-disable-next-line no-use-before-define
        askContactRequestInboundAcceptDialog(contactRequestInboundView.value)
      } else {
        setSelectedContactRequestInboundActionMultiple(status)

        selectedContactRequestInbounds.value = []
        isContactRequestInboundViewOpen.value = false
      }
    }

    // * If someone clicks on filter while viewing detail => Close the contactRequestInbound detail view
    watch(routeParams, () => {
      isContactRequestInboundViewOpen.value = false
    })

    // * Watcher to reset selectedContactRequestInbounds
    // ? You can also use isContactRequestInboundViewOpen (instead of `contactRequestInboundView`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([contactRequestInboundView, routeParams], () => {
      selectedContactRequestInbounds.value = []
    })

    // Accept ContactRequestInbound with Dialog
    const isContactRequestInboundAcceptDialogVisible = ref(false)
    const contactRequestInboundAcceptDialog = ref(null)
    const askContactRequestInboundAcceptDialog = contactRequestInbound => {
      isContactRequestInboundAcceptDialogVisible.value = true
      contactRequestInboundAcceptDialog.value = contactRequestInbound
    }
    const askContactRequestInboundAcceptDialogResponse = contactRequestInbound => {
      setSelectedContactRequestInboundActionMultiple(CONTACT_REQUEST_INBOUND_STATUS.ACCEPTED, [contactRequestInbound.id])
    }

    // Delete with dialog ask (With dependencies)
    const isContactRequestInboundDeleteDialogActive = ref(false)
    const contactRequestInboundDeleteId = ref('')
    const contactRequestInboundDeleteDependenciesError = ref(null)
    const askContactRequestInboundDelete = (contactRequestInboundId, dempendenciesError = null) => {
      isContactRequestInboundDeleteDialogActive.value = true
      contactRequestInboundDeleteId.value = contactRequestInboundId

      contactRequestInboundDeleteDependenciesError.value = dempendenciesError
    }

    const onContactRequestInboundDelete = (contactRequestInboundId, force = false) => {
      console.log(force)
      useContactRequestInbound
        .removeContactRequestInbound(contactRequestInboundId)
        .then(() => {
          // Unselect current selection
          selectedContactRequestInbounds.value = []
          isContactRequestInboundViewOpen.value = false
          contactRequestInboundView.value = {}

          sendContactRequestInboundNotification('ContactRequestInbound Deleted', 'success')
          fetchContactRequestInbounds()
        })
        .catch(error => {
          // Checking... Dependencies error
          // if (error.response && error.response.data && error.response.data.error) {
          if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.code === 'contact_request_inbound_dependencies_error') {
              // if (error.response.data.error.campaigns || error.response.data.error.contactRequests) {
              // Send dependencies
              //  campaigns: error.response.data.error.campaigns,
              //  contactRequests: error.response.data.error.contactRequests,
              askContactRequestInboundDelete(contactRequestInboundId, error.response.data.error)
            } else {
              sendContactRequestInboundNotification(error.response.data.message, 'error')
            }
          }
        })
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isLeftSidebarOpen,
      contentStyles,

      // Search Query
      searchQuery,

      // ContactRequestInbounds & ContactRequestInbound Meta
      contactRequestInbounds,
      contactRequestInboundsMeta,
      fetchContactRequestInbounds,
      loading,

      // Refresh and loadmore
      onRefresh,
      onLoad,
      finished,
      loadMoreError,
      refLoadMore, // Load more ref DOM (Scroll control)

      // ContactRequestInbound Selection
      selectedContactRequestInbounds,
      toggleSelectedContactRequestInbound,
      selectAllContactRequestInboundCheckbox,
      isSelectAllContactRequestInboundCheckboxIndeterminate,
      selectAllCheckboxUpdate,
      isAllSelectedContactRequestInboundAreRead,

      // ContactRequestInbound Actions
      toggleStarred,
      updateSelectedContactRequestInboundLabelMultiple,
      markSelectedContactRequestInboundAsUnreadMultiple,
      markSelectedContactRequestInboundAsReadMultiple,
      deleteSelectedContactRequestInboundMultiple,
      setSelectedContactRequestInboundActionMultiple,

      // ContactRequestInbound View
      isContactRequestInboundViewOpen,
      contactRequestInboundView,
      openContactRequestInboundMeta,
      updateContactRequestInboundView,
      updateOpenContactRequestInboundLabel,
      markOpenContactRequestInboundAsUnread,
      changeOpenContactRequestInbound,
      setOpenContactRequestInboundAction,

      // ContactRequest Inbound Ask Accept
      isContactRequestInboundAcceptDialogVisible,
      contactRequestInboundAcceptDialog,
      askContactRequestInboundAcceptDialog,
      askContactRequestInboundAcceptDialogResponse,

      // useContactRequestInbound
      resolveContactRequestInboundLabelColor,
      resolveContactRequestInboundStatus,
      customActionAccepted,
      customActionRejected,
      updateLabelMenuListItems,
      CONTACT_REQUEST_INBOUND_STATUS,

      // CRUD Delete
      isContactRequestInboundDeleteDialogActive,
      contactRequestInboundDeleteId,
      contactRequestInboundDeleteDependenciesError,
      askContactRequestInboundDelete,
      onContactRequestInboundDelete,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Filter/Formatter
      formatDateToMonthShort,

      // Icons
      icons: {
        // Menu,
        mdiTune,
        mdiMagnify,

        // Actions
        mdiTrashCanOutline,
        mdiReload,

        // Starred,
        mdiStarOutline,
        mdiStar,

        // Read / Unread
        mdiEmailOutline,
        mdiEmailOpenOutline,
      },
    }
  },
}
</script>

<!-- LoadMore -->
<style lang="css">

  /* Para hacer scroll dentro del div (Usar si indicamos altura fija) */
  .vuejs-loadmore-wrap {
    overflow: scroll;
  }
  /* En escritorio deshabilita la parte superior (Unos 64px que son usados para arrastrar y soltar) */
  .vuejs-refresh-head {
    z-index: -10;
  }
  .vuejs-refresh-track {
  }
</style>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  @import '~@core/preset/preset/mixins';

  .app-contact-request-inbound {
    // Style search input
    .contact-request-inbound-search-input {
      .v-input__slot {
        fieldset {
          border: none !important;
        }
      }
    }
  }

  @include theme(contact-request-inbound-search-input) using ($material) {
    .v-input__slot {
      input {
        caret-color: map-deep-get($material, 'text', 'primary') !important;
      }
    }
  }

  .ps-contact-request-inbounds-list {
    // height: calc(100% - 96px - 2px); // 96+2 deben ser el footer que no lleva
    height: calc(100%);

    .contact-request-inbound-item {
      &.hovered {
        transform: translateY(-2px);
        transition: all 0.2s;
        z-index: 1;
      }
    }
  }

  @include theme--child(ps-contact-request-inbounds-list) using ($material) {
    .contact-request-inbound-item {
      &:not(:last-child) {
        border-bottom: 1px solid map-deep-get($material, 'dividers');
      }
      &.contact-request-inbound-read {
        background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
      }
    }
  }
</style>
